
<template>
    <div class="account-page py-5 pb-lg-6">
       <section class="">
            <div class="row g-3">
                <div class="col-12">
                    <button @click="$router.go(-1)" type="button" class="btn btn-light"> <i class="mdi mdi-arrow-left"></i> Back</button> 
                </div>
                <div v-if="activeUser" class="col-lg-12">
                    <div class="row gy-4">
                        <div class="col-lg-4 col-xl-4">
                            <div class="card">
                                <div class="card-body text-center">
                                    <img :src="absoluteUrl(activeUser.image)" class="rounded-circle user-photo img-thumbnail" alt="profile-image" />
                                    <h4 class="mt-3 mb-0">{{activeUser.first_name}} {{activeUser.last_name}}</h4>
                                    <p class="text-muted">Type: {{activeUser.role}}</p>
                                    <button type="button" class="btn mr-1 btn-success btn-xs waves-effect mb-2 waves-light">
                                    {{activeUser.status}}
                                    </button>
                                    <span>
                                    <button type="button" class="btn btn-xs waves-effect mb-2 waves-light" :class="activeUser.is_verified ? 'btn-dark' : 'btn-danger'">
                                    {{activeUser.is_verified ? 'Verified' : 'Not Verified'}}
                                    </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8 col-xl-8">
                            <b-tabs content-class="" pills nav-class="nav-tabs brand-nav-design nav-bordered">
                                <b-tab :active="activeTab === 'profile'">
                                    <template v-slot:title>
                                        <i class="mdi mdi-account-check mr-1"></i>
                                        <span class="nav-label">Profile</span>
                                    </template>
                                    <div class="card rounded-0" >
                                        <div class="card-body">
                                            <user-edit-tab-account />
                                        </div>
                                    </div>
                                </b-tab>
                                <b-tab :active="activeTab === 'settings'">
                                    <template v-slot:title>
                                        <i class="mdi mdi-account-cog mr-1"></i>
                                        <span class="nav-label">Settings</span>
                                    </template>
                                     <div class="card rounded-0" >
                                        <div class="card-body">
                                            <user-edit-tab-information />
                                        </div>
                                    </div>
                                </b-tab>
                                <b-tab :active="activeTab === 'password'">
                                    <template v-slot:title>
                                        <i class="mdi mdi-lock-check mr-1"></i>
                                        <span class="nav-label">Change Password</span>
                                    </template>
                                     <div class="card rounded-0" >
                                        <div class="card-body">
                                            <user-edit-tab-password />
                                        </div>
                                    </div>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>
                </div>
            </div>
       </section>
    </div>
</template>

<script>
import UserEditTabAccount     from "./UserEditTabAccount.vue"
import UserEditTabInformation from "./UserEditTabInformation.vue"
import UserEditTabPassword from "./UserEditTabPassword.vue"

export default {
  name: "user-edit",
  components: {
    UserEditTabAccount,
    UserEditTabInformation,
    UserEditTabPassword,
  },
  computed: {
    activeUser(){
      return this.$store.state.activeUser
    },
    activeTab(){
      return this.$route.query.tab ? this.$route.query.tab : 'profile';
    },
  },
  created() {
      this.$store.dispatch("fetchActiveUser");
  }
}

</script>
<style scoped>
.user-photo{
    width:10rem;
    height:10rem !important;
}
</style>
